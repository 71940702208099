import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs12() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs12"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog12.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
            <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog12.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog12.title")}</h2>
                </div>
              </div>
              <div className="blog12-analysis">
                <p className="mt-5">{t("blog12.para")}</p>
                <h6 className="mt-5">{t("blog12.que1.q")}</h6>
                <p className="mt-5">{t("blog12.que1.ans")}</p>
                <p>{t("blog12.que1.1")}</p>
                <h6 className="mt-5">{t("blog12.que1.why")}</h6>
                <p>{t("blog12.que1.whypara")}</p>
                <p className="mt-5">{t("blog12.que1.2")}</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;{t("blog12.que1.b")}</p>
                <p className="mt-5">{t("blog12.que1.2para")}</p>
                <p className="mt-5">{t("blog12.que1.3para")}</p>
                <p className="mt-5">{t("blog12.que1.4.1para")}</p>
                <p>{t("blog12.que1.4.2para")}</p>
                <h6 className="mt-5">{t("blog12.procedure.title")}</h6>
                <p>{t("blog12.procedure.para")}</p>
                <p className="mt-5">{t("blog12.procedure.1")}</p>
                <p>{t("blog12.procedure.1.1")}</p>
                <p>{t("blog12.procedure.1.2")}</p>
                <p>{t("blog12.procedure.1.3")}</p>
                <p>{t("blog12.procedure.1para")}</p>
                <p className="mt-5">{t("blog12.procedure.2")}</p>
                <p>{t("blog12.procedure.2.1")}</p>
                <p className="mt-5">{t("blog12.procedure.2.2")}</p>
                <p className="mt-5">{t("blog12.procedure.3")}</p>
                <p className="mt-5">{t("blog12.procedure.4")}</p>
                <h6 className="mt-5">{t("blog12.procedure.result")}</h6>
                <p className="mt-5">{t("blog12.procedure.result1")}</p>
                <p >{t("blog12.procedure.result2")}</p>
                <p className="mt-5">{t("blog12.procedure.result3")}</p>
                <h6 className="mt-5">{t("blog12.que2.q")}</h6>
                <p className="mt-5">{t("blog12.que2.para")}</p>
                <h6 className="mt-5">{t("blog12.que3.q")}</h6>
                <p>{t("blog12.que3.para")}</p>
                <p>{t("blog12.que3.para1")}</p>
                <img className="mt-5" src="/SiteTemplate/TirrentGlobal/assets/img/oil.jpeg" />
                <h6 className="mt-5">{t("blog12.Conclusion.cons")}</h6>
                <p>{t("blog12.Conclusion.para")}</p>
                <div className="tags">
                <p><span>{t("blog12.tag")}</span><br/> {t("blog12.paraseven")}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs12;
