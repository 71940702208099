import React, { Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import LoadingSpinner from "../../View/LoadingSpinner";
import About from "./Pages/About";
import Blogs from "./Pages/Blogs";
import Blogs1 from "./Pages/Blogs1";
import Blogs2 from "./Pages/Blogs2";
import Blogs3 from "./Pages/Blogs3";
import Blogs4 from "./Pages/Blogs4";
import Blogs5 from "./Pages/Blogs5";
import Blogs6 from "./Pages/Blogs6";

import Club from "./Pages/Club";
import Contact from "./Pages/Contact";
import ErrorPage from "./Pages/ErrorPage";
import Faq from "./Pages/Faq";
import Home from "./Pages/Home";
import PoliciesProcedures from "./Pages/PoliciesProcedures";
import PolicyGuidelines from "./Pages/PolicyGuidelines";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ProductPage from "./Pages/ProductPage";
import ResearchPage from "./Pages/ResearchPage";
import SellerAgreement from "./Pages/SellerAgreement";
import SocialRes from "./Pages/SocialRes";
import Suppliers from "./Pages/Suppliers";
import TermsAndCondtion from "./Pages/TermsAndCondition";
import TermsOfUse from "./Pages/TermsOfUse";
import Termination from "./Pages/TerminationPolicy";
import Shipping from "./Pages/ShippingPolicy";
import Cancellation from "./Pages/CancellationPolicy";
import PaymentPage from "./Pages/PaymentPage";
import PaymentStatus from "./Pages/PaymentStatus";
import ProductDetails from "./Pages/ProductDetails";
import Login from "./Shared/Login";
import Register from "./Shared/Register";
import Cart from "./Pages/Cart";
import Support from "./Pages/Support";
import History from "./Pages/History";
import Profile from "./Pages/Profile";
import ForgetPassword from "./Shared/ForgetPassword";
import Gallery from "./Pages/Gallery";
import Blogs7 from "./Pages/Blogs7";
import Blogs8 from "./Pages/Blogs8";
import Blogs9 from "./Pages/Blogs9";
import GalleryEvents from "./Pages/GalleryEvents";
import Blogs10 from "./Pages/Blogs10";
import Blogs11 from "./Pages/Blogs11";
import Blogs12 from "./Pages/Blogs12";
import ProductBooster from "./Pages/ProductBooster";
import ProductMileage from "./Pages/ProductMileage";
import ProductSpray from "./Pages/ProductSpray";
import ProductGrease from "./Pages/ProductGrease";
import OilAnalysis from "./Pages/OilAnalysis";
import Blogs13 from "./Pages/Blogs13";
import Blogs14 from "./Pages/Blogs14";

function SiteRoute() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  const SiteTemplate = useMemo(() => React.lazy(() => import(`./index`)), []);
  const pathLocation = useLocation();

  return (
    <>
      {loading === false ? (
        <Suspense>
          <SiteTemplate pathname={pathLocation}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forget-password" element={<ForgetPassword />} />

              <Route path="/about" element={<About />} />
              <Route path="blogs" element={<Blogs />} />
              <Route
                path="blog/most-common-automobile-engine-problems-and-solutions"
                element={<Blogs1 />}
              />
              <Route
                path="blog/solutions-to-common-vehicle-problems"
                element={<Blogs2 />}
              />
              <Route
                path="blog/a-revolutionary-lubricating-experience"
                element={<Blogs3 />}
              />
              <Route
                path="blog/unleashing-hybrid-engine-enhancing-performance-with-tirrent-booster"
                element={<Blogs4 />}
              />
              <Route
                path="blog/boosting-performance-and-protecting-your-engine"
                element={<Blogs5 />}
              />
              <Route
                path="blog/why-timely-oil-changes-are-crucial"
                element={<Blogs6 />}
              />
              <Route
                path="blog/tirrent-global-solutions-for-the-marine-industry"
                element={<Blogs7 />}
              />
              <Route
                path="blog/the-advantage-of-tirrent-global"
                element={<Blogs8 />}
              />
              <Route
                path="blog/the-role-of-tirrent-global"
                element={<Blogs9 />}
              />
              <Route
                path="blog/is-your-vehicle-sipping-more-fuel-than-it-should"
                element={<Blogs10 />}
              />
              <Route
                path="blog/lubricating-a-sustainable-and-efficient-future"
                element={<Blogs11 />}
              />
              <Route
                path="blog/what-is-used-oil-analysis"
                element={<Blogs12 />}
              />
              <Route
                path="blog/the-synergy-of-used-oil-analysis-and-lubricity"
                element={<Blogs13 />}
              />
              <Route
                path="blog/sheikha-hend-faisal-al-qasimi-joins-tirrent-global-as-honorary-director"
                element={<Blogs14 />}
              />

              <Route path="support" element={<Support />} />

              <Route path="contact" element={<Contact />} />
              <Route path="oursuppliers" element={<Suppliers />} />
              <Route path="products/:id" element={<ProductPage />} />
              <Route
                path="product/tirrent-booster"
                element={<ProductBooster />}
              />
              <Route
                path="product/fuel-conditioner"
                element={<ProductMileage />}
              />
              <Route path="product/tg-10" element={<ProductSpray />} />
              <Route
                path="product/ep-2-lithium-complex-grease"
                element={<ProductGrease />}
              />
              <Route path="product-details" element={<ProductDetails />} />
              <Route path="cart" element={<Cart />} />
              <Route path="social-resposibility" element={<SocialRes />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="club" element={<Club />} />
              <Route path="termsandconditions" element={<TermsAndCondtion />} />
              <Route path="faq" element={<Faq />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="events/:id" element={<GalleryEvents />} />

              <Route path="solutions" element={<ResearchPage />} />
              <Route path="termsofuse" element={<TermsOfUse />} />
              <Route path="termination-policy" element={<Termination />} />
              <Route path="shipping-policy" element={<Shipping />} />
              <Route path="cancellation-policy" element={<Cancellation />} />
              {/* <Route path="instructions" element={<Insturctions />} /> */}
              <Route
                path="policy-and-guideline"
                element={<PolicyGuidelines />}
              />
              <Route path="seller-agreement" element={<SellerAgreement />} />
              <Route
                path="policies-procedure"
                element={<PoliciesProcedures />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="history-previous" element={<History />} />
              <Route path="payment-method" element={<PaymentPage />} />
              <Route path="payment-status" element={<PaymentStatus />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="oil-analysis" element={<OilAnalysis />} />

              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </SiteTemplate>
        </Suspense>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}

export default SiteRoute;
