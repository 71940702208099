import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation(["About"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("AboutHeading.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 col-md-12 company-dec"
              style={{ display: "grid", alignItems: "center" }}
            >
              <div className="">
                <h1 className="research-heading">{t("company.title")}</h1>
                <p>{t("company.p1")}</p>
                <p>{t("company.p2")}</p>
                <p>{t("company.p3")}</p>
               
              </div>
            </div>
            <div
              className="col-lg-4 company-img"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/about/about.png"
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="offer-area mission-vision offer-area-four pt-100 pb-70"
        style={{ backgroundColor: "#1d232a" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/about/3.png"
                    alt="Offer"
                    width="150px"
                  />
                </div>
                <div className="offer-bottom" style={{ marginTop: "20px" }}>
                  <h3>{t("values.mission.heading")}</h3>
                  <p>{t("values.mission.description")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/about/1.png"
                    alt="Offer"
                    width="150px"
                  />
                </div>
                <div className="offer-bottom" style={{ marginTop: "20px" }}>
                  <h3>{t("values.vision.heading")}</h3>
                  <p>{t("values.vision.description")}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/about/2.png"
                    alt="Offer"
                    width="165px"
                  />
                </div>
                <div className="offer-bottom">
                  <h3>{t("values.values.heading")} </h3>
                  <p>{t("values.values.description")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section">
        <div className="container" style={{ position: "relative" }}>
          <div className="row">
            <div
              className="col-lg-4  "
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/about/OurComnany.png"
                width="100%"
              />
            </div>
            <div
              className="col-lg-8 services-heading"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="services-content">
                <div
                  className="services-number"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h1 className="research-heading">{t("addInfo.heading")}</h1>
                </div>
                <p>{t("addInfo.line1")}</p>
                <ul style={{paddingLeft:"21px"}}>
                  <li>
                    <span style={{fontWeight:"550"}}>{t("addInfo.InfoHead1")}</span>
                    {t("addInfo.Infoline1")}
                  </li>
                  <li>
                    <span style={{fontWeight:"550"}}>{t("addInfo.InfoHead2")}</span>
                    {t("addInfo.Infoline2")}
                  </li>
                  <li>
                    <span style={{fontWeight:"550"}}>{t("addInfo.InfoHead3")}</span>
                    {t("addInfo.Infoline3")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="mt-5 mb-5"
            style={{ display: "flex", alignItems: "center" }}
          ></div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
