import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs5() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog5"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog5.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog5.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog5.para")}</p>
                <h6 className="mt-5">{t("blog5.1point")}</h6>
                <p>{t("blog5.1pointpara")}</p>

                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog5.1pointsub1")}</b>
                      {t("blog5.1pointsubpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog5.1pointsub2")}</b>
                      {t("blog5.1pointsubpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog5.1pointsub3")}</b>
                      {t("blog5.1pointsubpara3")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog5.1pointsub4")}</b>
                      {t("blog5.1pointsubpara4")}
                    </p>
                  </li>
                </ul>
                <p>{t("blog5.1pointpara2")}</p>

                <h6 className="mt-5">{t("blog5.2point")}</h6>
                <p>{t("blog5.2pointpara")}</p>
                <p>{t("blog5.2pointpara2")}</p>
                <p>{t("blog5.2pointpara3")}</p>

                <ul>
                  <li>
                    <p>
                      <b>{t("blog5.2pointsubpoint1")}</b>
                      {t("blog5.2pointsubpointpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog5.2pointsubpoint2")}</b>
                      {t("blog5.2pointsubpointpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog5.2pointsubpoint3")}</b>
                      {t("blog5.2pointsubpointpara3")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog5.2pointsubpoint4")}</b>
                      {t("blog5.2pointsubpointpara4")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog5.2pointsubpoint5")}</b>
                      {t("blog5.2pointsubpointpara5")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog5.4point")}</h6>
                <p>{t("blog5.4pointpara")}</p>

                <h6 className="mt-5">{t("blog5.5point")}</h6>
                <p>{t("blog5.5pointpara")}</p>

                <h6 className="mt-5">{t("blog5.6point")}</h6>
                <p>{t("blog5.6pointpara")}</p>
                <h6 className="mt-5">{t("blog5.7point")}</h6>

                <div className="tags">
                  <p>
                    <span>{t("blog5.tag")}</span>
                    <br /> {t("blog5.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs5;
