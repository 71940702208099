import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import { Link } from "react-router-dom";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { useTranslation } from "react-i18next";
import { locationContext } from "../Context/LocationContext";

function Home() {
  const { t } = useTranslation(["home"]);
  // const myRef = useRef();
  const { accessToBuyProduct } = useContext(locationContext);

  const [startCounter, setStartCounter] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setStartCounter(entry.isIntersecting);
    });
    // observer.observe(myRef.current);
  }, []);
  const responsive = {
    0: { items: 1 },
    350: { items: 2 },
    700: { items: 3 },
    1050: { items: 3 },
  };
  console.log(accessToBuyProduct, "accessToBuyProduct");
  const items = [
    <div className="item foreign-item" defaultValue="1">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-jcb.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.first")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="2">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-car.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.second")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="3">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/machine.png"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.third")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="4">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-bus.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.fourth")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="5">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-tractor.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.fifth")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="6">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Untitled design (6).png"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.sixth")}</span>
      </div>
    </div>,
    // <div className="item" data-value="2">2</div>,
    // <div className="item" data-value="3">3</div>,
    // <div className="item" data-value="4">4</div>,
    // <div className="item" data-value="5">5</div>,
  ];
  const techResponsive = {
    0: { items: 1 },
    600: { items: 2 },
    700: { items: 2 },
    1000: { items: 3 },
  };
  const techItems = [
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Tirrent Particles.png"
          className="card__image"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.first.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.first.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Anti-Aging Property.png"
          className="card__image"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
            />
            <div className="card__header-text">
              <h3 className="card__title"> {t("third.second.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.second.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Quantum Technology.png"
          className="card__image"
          alt="img"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
              alt="img"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.third.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.third.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/viscoelasticity.png"
          className="card__image"
          alt="img"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
              alt="img"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.fourth.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.fourth.para")}</p>
        </div>
      </a>
    </li>,
  ];

  return (
    <>
      <Header />

      <div className="banner-area banner-img-one">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="banner-content">
                    <h1>{t("Banner.heading")}</h1>
                    <p>{t("Banner.description")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="" style={{ position: "relative" }}>
        <section
          className=" about-area about-area-two pt-100"
          style={{ background: "#fff" }}
        >
          <div className="container">
            <div className="row align-iems-center">
              <div className="col-lg-6 american-llc">
                <div className="section-title">
                  <span className="sub-title">{t("second.subHeading")}</span>

                  <h2>
                    {t("second.heading")}
                    <br />
                    {t("second.heading2")}
                  </h2>
                </div>
                <div className="about-content">
                  <p>{t("second.para")}</p>
                  <h5 style={{ color: "#F99F36", fontWeight: "bold" }}>
                    {t("second.visionHeading")}
                  </h5>
                  <p>{t("second.vision")}</p>
                  <Link to="/solutions" className="cmn-btn">
                    {t("second.button")}
                    <i className="fa fa-right-arrow-lt"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 american-img">
                <div className="about-img">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/home/Technology.jpg"
                    alt="About"
                    width="80%"
                  />
                  <img
                    className="bounce"
                    src="/SiteTemplate/TirrentGlobal/assets/img/home/en (1).png"
                    alt="About"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="about-tirrent">
          <img
            src="/SiteTemplate/TirrentGlobal/assets/img/home/products.jpg"
            width="100%"
          />
        </div>
        <section
          className="whychooseus pt-100 pb-70"
          style={{ background: "#fff" }}
        >
          <div className="row m-0 p-0">
            <div
              className="col-md-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/home/why choose.png"
                alt="Job"
                width="100%"
              />
            </div>
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <div class="section-title" style={{ textAlign: "left" }}>
                  <h2 style={{ padding: "0px 40px" }}>
                    {t("whychooseus.heading")}
                  </h2>
                </div>
                <ul>
                  <li>{t("whychooseus.one")}</li>
                  <li>{t("whychooseus.two")}</li>
                  <li>{t("whychooseus.three")}</li>
                  <li>{t("whychooseus.four")}</li>
                  <li>{t("whychooseus.five")}</li>
                  <li>{t("whychooseus.six")}</li>
                  <li>{t("whychooseus.seven")}</li>
                  <li>{t("whychooseus.eight")}</li>
                  <li>{t("whychooseus.nine")}</li>
                  <li>{t("whychooseus.ten")}</li>
                  <li>{t("whychooseus.eleven")}</li>
                  <li>{t("whychooseus.twelve")}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-100 pb-70" style={{ background: "#1D232A" }}>
          <div className="container" style={{ textAlign: "center" }}>
            <div className="section-title">
              <img
                src="./SiteTemplate/TirrentGlobal/assets/img/industry.png"
                width="3%"
              />
              <h2>{t("industries.title")}</h2>
            </div>
            <div className="industries">
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Automotive.png" />
                <p> {t("industries.one")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Industrial.png" />
                <p> {t("industries.two")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Construction.png" />
                <p> {t("industries.three")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Mining.png" />
                <p> {t("industries.four")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/oil-gas.png" />
                <p> {t("industries.five")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Marine.png" />
                <p> {t("industries.six")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Agricultural.png" />
                <p> {t("industries.seven")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Military.png" />
                <p> {t("industries.eight")}</p>
              </div>
              <div className="industries-item">
                <img src="./SiteTemplate/TirrentGlobal/assets/img/home/industries/Railways.png" />
                <p> {t("industries.nine")}</p>
              </div>
            </div>
            <Link to="/solutions" className="cmn-btn mt-5">
              {t("industries.readmore")}
              <i className="fa fa-right-arrow-lt"></i>
            </Link>
          </div>
        </section>

        <div className="technologies">
          <h2> {t("technologies.title")}</h2>
          {/* <div className="container-fluid"> */}
          <div className="section-title"></div>
          <div className="row m-0">
            <div className="col-lg-6 ">
              <div className="section-title technologies1 tech1">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/home/icons1.png"
                  width="8%"
                />
                <h2> {t("technologies.1")}</h2>
                <div class="line"></div>
                <p className="mt-5">{t("technologies.para1")}</p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="section-title technologies1 tech1">
              <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/home/icons2.png"
                  width="8%"
                />
                <h2> {t("technologies.2")}</h2>
                <div class="line"></div>
                <p className="mt-5">{t("technologies.para2")}</p>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* <section className="counter-area pt-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">{t("sixth.subHeading")}</span>
              <h2 style={{ color: "white" }}>{t("sixth.heading")}</h2>
            </div>
            <div className="row align-iems-center">
              <div className="col-lg-12">
                <div className="row" ref={myRef}>
                  <div className="col-md-12 d-flex justify-content-center">
                    <img
                      src={`./SiteTemplate/TirrentGlobal/assets/img/${t(
                        "sixth.img"
                      )}.png`}
                      className="verticalChart"
                      style={{ width: "75%" }}
                    />
                    <img
                      src="./SiteTemplate/TirrentGlobal/assets/img/Engine's.png"
                      className="sm-screen-quality"
                    />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="foreign-area">
          <div className="container-fluid">
            <div className="container">
              <div className="section-title">
                <h2> {t("foreign-area.heading")}</h2>
                <p>{t("foreign-area.para")}</p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/automobiles.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Automobile</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/construction.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Construction</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/agriculture.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Agriculture</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/industrial.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Industrial</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/marine.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Marine</a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="item foreign-item">
                    <a href="project-details.html">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/mining.jpg"
                        alt="Foreign"
                      />
                    </a>
                    <div class="foreign-bottom">
                      <h3>
                        <a href="project-details.html">Mining</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section className="partner-area mb-5">
          <div className="container">
            <div className="partner-wrap">
              <div className="partner-shape">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/home/partner-shape.png"
                  alt="Partner"
                  width="85%"
                />
              </div>

              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="partner-content">
                    <div className="section-title">
                      <h2>{t("ninth.heading")}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="partner-btn">
                    <Link to="/contact" className="cmn-btn">
                      {t("ninth.button")}
                      <i
                        className="fa-solid fa-chevron-right"
                        style={{ marginLeft: "8px" }}
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="blog-area blog-area-two pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">{t("tenth.subHeading")}</span>
              <h2>{t("tenth.heading")}</h2>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <div className="blog-item">
                  <Link to="blog-1">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "tenth.img.1"
                      )}.png`}
                      alt="Blog"
                      width="100%"
                    />
                  </Link>

                  <div className="blog-inner">
                    <h3>
                      <Link to="blog-1">{t("tenth.points.first")}</Link>
                    </h3>
                    <Link className="blog-link" to="blog-1">
                      {t("tenth.pointsSubHeading")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="blog-item">
                  <Link to="blog-2">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "tenth.img.2"
                      )}.png`}
                      alt="Blog"
                      width="100%"
                    />
                  </Link>
                  <div className="blog-inner">
                    <h3>
                      <Link to="blog-2">{t("tenth.points.second")}</Link>
                    </h3>
                    <Link className="blog-link" to="blog-2">
                      {t("tenth.pointsSubHeading")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="blog-item">
                  <Link to="blog-3">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "tenth.img.3"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>

                  <div className="blog-inner">
                    <h3>
                      <Link to="blog-3">{t("tenth.points.third")}</Link>
                    </h3>
                    <Link className="blog-link" to="blog-3">
                      {t("tenth.pointsSubHeading")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link to="/blogs" className="cmn-btn">
                {t("tenth.pointsSubHeading")}
                <i
                  className="fa-solid fa-chevron-right"
                  style={{ marginLeft: "8px" }}
                ></i>
              </Link>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </>
  );
}

export default Home;
