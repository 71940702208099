import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs1() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs1"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog1.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog1.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog1.main-title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog1.para")}</p>
                <ol>
                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/1.png"
                      width="100%"
                    />
                    <b>{t("blog1.1point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.1subpoint1")}</b>
                          {t("blog1.1subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.1subpoint2")}</b>
                          {t("blog1.1subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/2.png"
                      width="100%"
                    />
                    <b>{t("blog1.2point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.2subpoint1")}</b>
                          {t("blog1.2subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.2subpoint2")}</b>
                          {t("blog1.2subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/3.png"
                      width="100%"
                    />
                    <b>{t("blog1.3point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.3subpoint1")}</b>
                          {t("blog1.3subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.3subpoint2")}</b>
                          {t("blog1.3subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/4.png"
                      width="100%"
                    />
                    <b>{t("blog1.4point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.4subpoint1")}</b>
                          {t("blog1.4subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.4subpoint2")}</b>
                          {t("blog1.4subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/5.png"
                      width="100%"
                    />
                    <b>{t("blog1.5point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.5subpoint1")}</b>
                          {t("blog1.5subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.5subpoint2")}</b>
                          {t("blog1.5subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/6.png"
                      width="100%"
                    />
                    <b>{t("blog1.6point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.6subpoint1")}</b>
                          {t("blog1.6subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.6subpoint2")}</b>
                          {t("blog1.6subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/7.png"
                      width="100%"
                    />
                    <b>{t("blog1.7point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.7subpoint1")}</b>
                          {t("blog1.7subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.7subpoint2")}</b>
                          {t("blog1.7subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/8.png"
                      width="100%"
                    />
                    <b>{t("blog1.8point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.8subpoint1")}</b>
                          {t("blog1.8subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.8subpoint2")}</b>
                          {t("blog1.8subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-1/9.png"
                      width="100%"
                    />
                    <b>{t("blog1.9point")}</b>
                    <ul>
                      <li>
                        <p>
                          <b>{t("blog1.9subpoint1")}</b>
                          {t("blog1.9subpointpara1")}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>{t("blog1.9subpoint2")}</b>
                          {t("blog1.9subpointpara2")}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>

                <h6 className="mt-5">{t("blog1.10point")}</h6>
                <p>{t("blog1.10pointpara")}</p>
                <h6 className="mt-5">{t("blog1.11point")}</h6>
                <p>{t("blog1.11pointpara")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog1.tag")}</span>
                    <br /> {t("blog1.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs1;
