import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs13() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs13"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog13.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog13.img"
                    )}.png`}
                    width="100%"
                  />
                  <h2>{t("blog13.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <h6 className="mt-5">{t("blog13.one")}</h6>
                <p>{t("blog13.paraone")}</p>
                <h6 className="mt-5">{t("blog13.two")}</h6>
                <p>{t("blog13.paratwo")}</p>
                <h6 className="mt-5">{t("blog13.three")}</h6>
                <p>{t("blog13.parathree")}</p>
                <p>{t("blog13.parathree1")}</p>
                <h6 className="mt-5">{t("blog13.four")}</h6>
                <p>{t("blog13.parafour")}</p>
                <h6 className="mt-5" style={{ color: "#FF0000" }}>
                  {t("blog13.warning")}
                </h6>
                <img
                  className="mt-5"
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog131.png"
                  width="100%"
                />
                <h6 className="mt-5">{t("blog13.five")}</h6>
                <p>{t("blog13.parafive")}</p>
                <h6 className="mt-5">{t("blog13.six")}</h6>
                <p>{t("blog13.parasix")}</p>
                <h6 className="mt-5">{t("blog13.seven")}</h6>
                <div className="tags">
                  <p>
                    <span>{t("blog13.tag")}</span>
                    <br /> {t("blog13.paraseven")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs13;
