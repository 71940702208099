import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { locationContext } from "../Context/LocationContext";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
function ProductSpray() {
  const { t } = useTranslation(["Product"]);
  const { accessToBuyProduct } = useContext(locationContext);

  const [tabs, setTabs] = useState(0);
  const tab = [
    <div class="tab-content active" id="tab-1">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {" "}
              {t("Product3.howtouse.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.howtouse.one")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.howtouse.two")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.howtouse.three")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.howtouse.four")}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="applications">
          <h5>
            <span className="product-booster">
              {t("Product3.benefits.title")}
            </span>
          </h5>
          <ul>
            <div className="row">
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product3.benefits.one")}
                    </h6>
                    <p>{t("Product3.benefits.paraone")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product3.benefits.two")}
                    </h6>
                    <p>{t("Product3.benefits.paratwo")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product3.benefits.three")}
                    </h6>
                    <p>{t("Product3.benefits.parathree")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product3.benefits.four")}
                    </h6>
                    <p>{t("Product3.benefits.parafour")}</p>
                  </div>
                </li>
              </div>

              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product3.benefits.five")}
                    </h6>
                    <p>{t("Product3.benefits.parafive")}</p>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </div>

        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {" "}
              {t("Product3.cautions.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.one")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.two")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.three")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.four")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.five")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <p>{t("Product3.cautions.six")}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>,

    <div class="tab-content active" id="tab-2">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product3.application.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraone")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paratwo")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parathree")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parafour")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parafive")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parasix")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraseven")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraeight")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paranine")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraten")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraeleven")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parartwelve")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parathirteen")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraforteen")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parafifteen")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.parasixteen")}</p>
                </div>
              </li>{" "}
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i
                  className="fa fa-check"
                  style={{
                    backgroundColor: "#f99f36",
                    padding: "1px",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                ></i>{" "}
                <div>
                  <p>{t("Product3.application.paraseventeen")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>,
  ];

  const navigate = useNavigate();
  const clickTab = (e) => {
    setTabs(e.target.id);
  };
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("Product3.span")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
            <div className="row mt-5">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="container-product"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/products/product.spray.png"
                    alt="img"
                    width="100%"
                  />
                </div>
              </div>
              <div className="col-lg-7 tirrent-booster">
                <h2>
                  <span className="product-booster">
                    {" "}
                    {t("Product3.span")}{" "}
                  </span>
                </h2>
                <h2> {t("Product3.title")} </h2>
                <p>{t("Product3.description")}</p>
                <div className="row">
                  <div className="col-md-8">
                    <div className="right-content keyfeatures">
                      <ul>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.one")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.two")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.three")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.four")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.five")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.six")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.seven")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            className="fa fa-check"
                            style={{
                              backgroundColor: "#f99f36",
                              padding: "1px",
                              borderRadius: "50%",
                              color: "#fff",
                            }}
                          ></i>{" "}
                          <div>
                            <p>{t("Product3.keyfeatures.seven")}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs-container">
              <div class="tabs">
                <div class="tab-links">
                  <button
                    class={tabs == "0" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="0"
                  >
                    <i class="fas fa-info-circle"></i> {t("Product.usage")}
                  </button>
                  <button
                    class={tabs == "1" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="1"
                  >
                    <i class="fas fa-list"></i> {t("Product.applications")}
                  </button>
                </div>
                {tab[tabs]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductSpray;
