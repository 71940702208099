import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs4() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog4"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog4.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog4.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog4.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog4.para")}</p>
                <h6 className="mt-5">{t("blog4.1point")}</h6>
                <p>{t("blog4.1pointpara")}</p>
               
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.1pointsub1")}</b>
                      {t("blog4.1pointsubpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.1pointsub2")}</b>
                      {t("blog4.1pointsubpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.1pointsub3")}</b>
                      {t("blog4.1pointsubpara3")}
                    </p>
                  </li>
                 
                </ul>

                <h6 className="mt-5">{t("blog4.2point")}</h6>
                <p>{t("blog4.2pointpara")}</p>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.2pointsubpoint1")}</b>
                      {t("blog4.2pointsubpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.2pointsubpoint2")}</b>
                      {t("blog4.2pointsubpointpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.2pointsubpoint3")}</b>
                      {t("blog4.2pointsubpointpara3")}
                    </p>
                  </li>
                </ul>


                <h6 className="mt-5">{t("blog4.3point")}</h6>
               
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.3pointsubpoint1")}</b>
                      {t("blog4.3pointsubpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.3pointsubpoint2")}</b>
                      {t("blog4.3pointsubpointpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.3pointsubpoint3")}</b>
                      {t("blog4.3pointsubpointpara3")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog4.3pointsubpoint4")}</b>
                      {t("blog4.3pointsubpointpara4")}
                    </p>
                  </li>
                </ul>


                <h6 className="mt-5">{t("blog4.4point")}</h6>
                <p>{t("blog4.4pointpara")}</p>
              
                <div className="tags">
                  <p>
                    <span>{t("blog4.tag")}</span>
                    <br /> {t("blog4.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs4;
