import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs7() {
  const { t } = useTranslation(["Blogs10"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog10.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog10.img"
                    )}.png`}
                    width="100%"
                  />
                  <h2>{t("blog10.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog10.para")}</p>
                <h6 className="mt-5">{t("blog10.1point")}</h6>
                <ul className="mt-4">
                  <li>
                    <p>
                      <b>{t("blog10.1subpoint1")}</b> {t("blog10.1subpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.1subpoint2")}</b> {t("blog10.1subpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.1subpoint3")}</b> {t("blog10.1subpara3")}
                    </p>
                  </li>
                </ul>
                <h6 className="mt-5">{t("blog10.2point")}</h6>
                <p>{t("blog10.2para")}</p>

                <h6 className="mt-5">{t("blog10.3point")}</h6>
                <p>{t("blog10.3para")}</p>

                <h6 className="mt-5">{t("blog10.4point")}</h6>
                <ul className="mt-4">
                  <li>
                    <p>
                      <b>{t("blog10.4subpoint1")}</b> {t("blog10.4subpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.4subpoint2")}</b> {t("blog10.4subpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.4subpoint3")}</b> {t("blog10.4subpara3")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog10.5point")}</h6>
                <ul className="mt-4">
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint1")}</b> {t("blog10.5subpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint2")}</b> {t("blog10.5subpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint3")}</b> {t("blog10.5subpara3")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint4")}</b> {t("blog10.5subpara4")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint5")}</b> {t("blog10.5subpara5")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.5subpoint6")}</b> {t("blog10.5subpara6")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog10.6point")}</h6>
                <p>{t("blog10.6pointpara")}</p>

                <ol className="mt-4">
                  <li>
                    <p>
                      <b>{t("blog10.6subpoint1")}</b> {t("blog10.6subpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.6subpoint2")}</b> {t("blog10.6subpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog10.6subpoint3")}</b> {t("blog10.6subpara3")}
                    </p>
                  </li>
                </ol>

                <h6 className="mt-5">{t("blog10.7point")}</h6>
                <p>{t("blog10.7pointpara")}</p>

                <h6 className="mt-5">{t("blog10.8point")}</h6>
                <ul className="mt-4">
                  <li>
                    <p>{t("blog10.8pointpara1")}</p>
                  </li>
                  <li>
                    <p>{t("blog10.8pointpara2")}</p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog10.9point")}</h6>
                <p>{t("blog10.9pointpara")}</p>

                <div className="tags"> 
                  <p>
                    <span>{t("blog10.tag")}</span>
                    <br /> {t("blog10.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs7;
