import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs11() {
  const { t } = useTranslation(["Blogs11"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog11.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog11.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog11.main-title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog11.para")}</p>
                <h6 className="mt-5">{t("blog11.1point")}</h6>
                <p>{t("blog11.1pointpara")}</p>
                <ul>
                  <li>
                    <p>
                      <b>{t("blog11.1subpoint1")}</b>
                      {t("blog11.1subpointpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.1subpoint2")}</b>
                      {t("blog11.1subpointpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.1subpoint3")}</b>
                      {t("blog11.1subpointpara3")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog11.2point")}</h6>
                <p>{t("blog11.2pointpara")}</p>

                <ul>
                  <li>
                    <p>
                      <b>{t("blog11.2subpoint1")}</b>
                      {t("blog11.2subpointpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.2subpoint2")}</b>
                      {t("blog11.2subpointpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.2subpoint3")}</b>
                      {t("blog11.2subpointpara3")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.2subpoint4")}</b>
                      {t("blog11.2subpointpara4")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.2subpoint5")}</b>
                      {t("blog11.2subpointpara5")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog11.3point")}</h6>
                <p>{t("blog11.3pointpara")}</p>
                <ul>
                  <li>
                    <p>
                      <b>{t("blog11.3subpoint1")}</b>
                      {t("blog11.3subpointpara1")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.3subpoint2")}</b>
                      {t("blog11.3subpointpara2")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{t("blog11.3subpoint3")}</b>
                      {t("blog11.3subpointpara3")}
                    </p>
                  </li>
                </ul>
                <h6 className="mt-5">{t("blog11.4point")}</h6>
                <p>{t("blog11.4pointpara")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog11.tag")}</span>
                    <br /> {t("blog11.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs11;
