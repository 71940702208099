import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs2() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs2"]);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <>
        <Header />
        <div className="page-title-area main-bg">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="title-item">
                  <h2>{t("blog2.title")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-details-area ptb-100">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-10">
                <div className="blog-details-item">
                  <div className="blog-details-name">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "blog2.img"
                      )}.png`}
                      width="100%"
                    />

                    <h2>{t("blog2.main-title")}</h2>
                  </div>
                </div>
                <div className="oil-analysis">
                  <p>{t("blog2.para")}</p>
                  <h6 className="mt-5">{t("blog2.1point")}</h6>
                  <ol>
                    <li>
                      <h6>{t("blog2.1subpoint1")}</h6>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.problems")}</b>
                            {t("blog2.1subpointpara1")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.solutions")}</b>
                            {t("blog2.1subpointpara2")}
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h6>{t("blog2.1subpoint2")}</h6>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.importance")}</b>
                            {t("blog2.1subpointpara3")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.frequency")}</b>
                            {t("blog2.1subpointpara4")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.TirrentBooster")}</b>
                            {t("blog2.1subpointpara5")}
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h6>{t("blog2.1subpoint3")}</h6>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.problems1")}</b>
                            {t("blog2.1subpointpara6")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.solutions1")}</b>
                            {t("blog2.1subpointpara7")}
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h6>{t("blog2.1subpoint4")}</h6>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.problems2")}</b>
                            {t("blog2.1subpointpara8")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.1subpointpara9")}</b>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <b>{t("blog2.lubricants")}</b>
                                {t("blog2.1subpointpara10")}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>{t("blog2.FuelSystem")}</b>
                                {t("blog2.1subpointpara11")}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>{t("blog2.elect")}</b>
                                {t("blog2.1subpointpara12")}
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>{t("blog2.1subpoint5")}</b>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.1subpointpara13")}</b>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <b>{t("blog2.extended")}</b>
                                {t("blog2.1subpointpara14")}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>{t("blog2.interval")}</b>
                                {t("blog2.1subpointpara15")}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>{t("blog2.proactive")}</b>
                                {t("blog2.1subpointpara16")}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>{t("blog2.performance")}</b>
                                {t("blog2.1subpointpara17")}
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.role")}</b>
                            {t("blog2.1subpointpara18")}
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>{t("blog2.1subpoint6")}</b>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <b>{t("blog2.problems3")}</b>
                            {t("blog2.1subpointpara19")}
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("blog2.solution3")}</b>
                            {t("blog2.1subpointpara20")}
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ol>

                  <h6 className="mt-5">{t("blog2.2point")}</h6>
                  <p>{t("blog2.2pointpara")}</p>
                  <div className="tags">
                    <p>
                      <span>{t("blog2.tag")}</span>
                      <br /> {t("blog2.tagpara")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default Blogs2;
