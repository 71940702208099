import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs8() {
  const { t } = useTranslation(["Blogs8"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog8.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog8.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog8.para")}</p>
                <h6 className="mt-5">{t("blog8.1point")}</h6>
                <p>{t("blog8.1pointpara")}</p>

                <h6 className="mt-5">{t("blog8.2point")}</h6>
                <p>{t("blog8.2pointpara")}</p>

                <h6 className="mt-5">{t("blog8.3point")}</h6>
                <p>{t("blog8.3pointpara")}</p>

                <h6 className="mt-5">{t("blog8.4point")}</h6>
                <p>{t("blog8.4pointpara")}</p>

                <h6 className="mt-5">{t("blog8.5point")}</h6>
                <p>{t("blog8.5pointpara")}</p>

                <h6 className="mt-5">{t("blog8.6point")}</h6>
                <p>{t("blog8.6pointpara")}</p>
                <h6 className="mt-5">{t("blog8.7point")}</h6>
                <p>{t("blog8.7pointpara")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog8.tag")}</span>
                    <br /> {t("blog8.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs8;
