import React, { useEffect } from "react";
import AOS from "aos";
import { Link, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useTranslation } from "react-i18next";
function Blogs3() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs3"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog3.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog3.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog3.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog3.para")}</p>
                <h6 className="mt-5">{t("blog3.1point")}</h6>
                <p>{t("blog3.1pointpara")}</p>

                <h6 className="mt-5">{t("blog3.3point")}</h6>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.2subpoint1")}</b>
                      {t("blog3.2subpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.2subpoint2")}</b>
                      {t("blog3.2subpointpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.2subpoint3")}</b>
                      {t("blog3.2subpointpara3")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.2subpoint4")}</b>
                      {t("blog3.2subpointpara4")}
                    </p>
                  </li>
                </ul>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-3/tirrent-booster.jpeg"
                  width="100%"
                />
                <h6 className="mt-5">{t("blog3.3point")}</h6>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.3pointsubpoint1")}</b>
                      {t("blog3.3pointsubpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.3pointsubpoint2")}</b>
                      {t("blog3.3pointsubpointpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.3pointsubpoint3")}</b>
                      {t("blog3.3pointsubpointpara3")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog3.3pointsubpoint4")}</b>
                      {t("blog3.3pointsubpointpara4")}
                    </p>
                  </li>
                </ul>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-3/oil.jpeg"
                  width="100%"
                />

                <h6 className="mt-5">{t("blog3.4point")}</h6>
                <ul>
                  <li>
                    <p>{t("blog3.4point1")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point2")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point3")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point4")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point5")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point6")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point7")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point8")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point9")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point10")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point11")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point12")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point13")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point14")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point15")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point16")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point17")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point18")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point19")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point20")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point21")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point22")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.4point23")}</p>
                  </li>
                </ul>

                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-3/sepration.jpeg"
                  width="100%"
                />


                <h6 className="mt-5">{t("blog3.5point")}</h6>

                <ul>
                  <li>
                    <p>{t("blog3.5point1")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point2")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point3")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point4")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point5")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point6")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point7")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point8")}</p>
                  </li>
                  <li>
                    <p>{t("blog3.5point9")}</p>
                  </li>
                </ul>
                <h6 className="mt-5">{t("blog3.6point")}</h6>
                <p>{t("blog3.4pointpara1")}</p>

                <div className="tags">
                  <p>
                    <span>{t("blog3.tag")}</span>
                    <br /> {t("blog3.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs3;
