import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs14() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog14"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog14.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/blog/blog-14/sheikha.jpeg"
                    width="100%"
                  />
                  <h2>{t("blog14.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
              <p>
                      <b>{t("blog14.span")}</b>
                      {t("blog14.para")}
                    </p>
                <p>{t("blog14.1point")}</p>
                <p>{t("blog14.2point")}</p>
                <p>{t("blog14.3point")}</p>
                <p>{t("blog14.4point")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog14.tag")}</span>
                    <br /> {t("blog14.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs14;
