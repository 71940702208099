import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function ResearchPage() {
  const { t } = useTranslation(["research"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("ResearchHeading.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h1 className="research-heading">
                  {t("researchHeading.title1")}
                </h1>
              </div>
              <div className="about-content">
                <p>{t("researchHeading.line2")}</p>
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title3")}
                </h4>

                <p>{t("researchHeading.line3")}</p>
                <p>{t("researchHeading.line5")}</p>
              </div>
            </div>
            <div className="col-lg-6 research-development">
              <div className="about-img-wrap">
                <div className="about-img-slider owl-theme">
                  <div className="about-img-item">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/tecno-page.png"
                      alt="About"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 complete-care">
        <div className="about-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title4")}
                </h4>
                <p>{t("researchHeading.line4")}</p>

                <ul>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading1.title")}
                    <p className="para">
                      {t("researchHeading.subheading1.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading2.title")}
                    <p className="para">
                      {t("researchHeading.subheading2.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading3.title")}
                    <p className="para">
                      {t("researchHeading.subheading3.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading4.title")}
                    <p className="para">
                      {t("researchHeading.subheading4.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading5.title")}
                    <p className="para">
                      {t("researchHeading.subheading5.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>{" "}
                    {t("researchHeading.subheading6.title")}
                    <p className="para">
                      {t("researchHeading.subheading6.description")}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 set-order">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/solutions/bf_AF.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-sm-6 col-lg-6 mt-5 text-center">
            <p color="#ffff">
              <span
                style={{
                  backgroundColor: "#1D232A",
                  padding: "10px",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                Without Tirrent Booster
              </span>
            </p>
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/solutions/before.jpg"
              alt="Project Details"
              width="100%"
            />
          </div>
          <div className="col-sm-6 col-lg-6 mt-5 text-center">
            <p color="#ffff">
              <span
                style={{
                  backgroundColor: "#1D232A",
                  padding: "10px",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                With Tirrent Booster
              </span>
            </p>
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/solutions/after.jpg"
              alt="Project Details"
              width="100%"
              i
            />
          </div>
        </div>
      </div>
      <div className="quote-section">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-4 pl-5 pt-5 pb-5 d-flex justify-content-center">
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/solutions/maintenance.png"
                alt=""
                width="80%"
              />
            </div>
            <div className="col-md-8 d-flex pr-5">
              <div className="title-item quote">
                <h2>{t("quote.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="project-details-area mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mt-5">
                <h1 className="research-heading">
                  {t("quote.industries.title")}
                </h1>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 p-2">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/Automotive.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Automotive</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/industrial.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Industrial</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/construction.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Construction</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2  ">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/mining.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Mining</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2  ">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/oil and gas.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Oil and Gas</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2  ">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/Marine.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Marine</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/agriculture.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Agriculture</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2  ">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/Military.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Military</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-2">
                  <div class="application-card">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/solutions/we-serve/Railway.jpg"
                      alt="Landscape"
                      class="card-image"
                    />

                    <div class="card-overlay"></div>

                    <div class="card-title">
                      <h3>Railways</h3>
                    </div>

                    <div class="card-content">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResearchPage;
