import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs7() {
  const { t } = useTranslation(["Blogs7"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog7.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog7.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog7.para")}</p>
                <h6 className="mt-5">{t("blog7.1point")}</h6>
                <p>{t("blog7.1pointpara")}</p>

                <h6 className="mt-5">{t("blog7.2point")}</h6>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog7.2subpoint1")}</b>
                      {t("blog7.2subpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog7.2subpoint2")}</b>
                      {t("blog7.2subpointpara2")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog7.2subpoint3")}</b>
                      {t("blog7.2subpointpara3")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog7.2subpoint4")}</b>
                      {t("blog7.2subpointpara4")}
                    </p>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog7.3point")}</h6>
                <p>{t("blog7.3pointpara")}</p>

                <h6 className="mt-5">{t("blog7.4point")}</h6>
                <p>{t("blog7.4pointpara")}</p>
                <p>{t("blog7.4pointpara1")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog7.tag")}</span>
                    <br /> {t("blog7.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs7;
