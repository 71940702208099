import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs6() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog6"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog6.main-title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog6.title")}</h2>
                </div>
              </div>
              <div className="oil-analysis">
                <p>{t("blog6.para")}</p>
                <p>{t("blog6.para1")}</p>
                <ul>
                  <li>{t("blog6.subpointpara1")}</li>
                  <li>{t("blog6.subpointpara2")}</li>
                  <li>{t("blog6.subpointpara3")}</li>
                  <li>{t("blog6.subpointpara4")}</li>
                </ul>
                <p>{t("blog6.para2")}</p>
                <p>{t("blog6.para3")}</p>
                <p>{t("blog6.para4")}</p>

                <h6 className="mt-5">{t("blog6.2point")}</h6>
                <p>{t("blog6.2pointpara")}</p>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog6.2subpoint1")}</b>
                      {t("blog6.2subpointpara1")}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog6.2subpoint2")}</b>
                      {t("blog6.2subpointpara2")}
                    </p>
                    <ul>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint.1subpointpara2subpoint1"
                            )}
                          </b>
                          {t(
                            "blog6.2subpointpara2subpoint.1subpointpara2subpointpara1"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint.1subpointpara2subpoint2"
                            )}
                          </b>
                          {t(
                            "blog6.2subpointpara2subpoint.1subpointpara2subpointpara2"
                          )}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog6.2subpoint3")}</b>
                      {t("blog6.2subpointpara3")}
                    </p>
                    <ul>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint2.1subpointpara2subpoint1"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint2.1subpointpara2subpoint2"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint2.1subpointpara2subpoint3"
                            )}
                          </b>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    <p>
                      <b>{t("blog6.2subpoint4")}</b>
                      {t("blog6.2subpointpara4")}
                    </p>
                    <ul>
                    <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint4.1subpointpara2subpoint1"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint4.1subpointpara2subpoint2"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint4.1subpointpara2subpoint3"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint4.1subpointpara2subpoint4"
                            )}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>
                            {t(
                              "blog6.2subpointpara2subpoint4.1subpointpara2subpoint5"
                            )}
                          </b>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>

                <h6 className="mt-5">{t("blog6.3point")}</h6>
                <p>{t("blog6.3pointpara")}</p>

                <h6 className="mt-5">{t("blog6.4point")}</h6>
                <p>{t("blog6.4pointpara")}</p>
                <div className="tags">
                  <p>
                    <span>{t("blog6.tag")}</span>
                    <br /> {t("blog6.tagpara")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs6;
